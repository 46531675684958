import React from "react";

interface Option {
  label: string;
  value: string;
}

interface  DropdownProps {
    value: string;
    options: Option[];
    onChange: (value: string) => void;
}

export const Dropdown = (
    props: DropdownProps
) => {
    const { value, options, onChange } = props;
    return (
        <label>
            <select value={value} onChange={(e) => {
                onChange(e.target.value);

            }} style={{
                width: "100%",
                padding: "0 0.75rem",
                height: 32,
            }}>
                {options.map((option) => (
                    <option value={option.value} key={option.value}>{option.label}</option>
                ))}
            </select>
        </label>
    );
};