import React, {useState} from "react";
import Seo from "../components/seo"
import * as R from 'ramda';
import Layout, {BlackLink, SegmentLink, StyledLink} from "../components/layout";
import {graphql, navigate} from "gatsby";
import {BlackLine, DottedLine} from "../components/line";
import {FiShoppingCart, FiX} from 'react-icons/fi';
import config from '../../config';

import axios from "axios"
import {Shipping} from "../models/shipping";
import {Radio} from "../components/radio";
import {Dropdown} from "../components/dropdown";
import countries from '../../static/countries.json';
import taiwanCities from '../../static/taiwan_cities.json';
import {Field, TextField} from "../components/field";
import queryString from 'query-string';
import {CompactButton} from "../components/button";
import {Payment} from "../models/payment";
import {useIsMobile} from "../utils";

const Checkout = ({data, location}) => {
    const query = queryString.parse(location.search);

    const paymentMethods: Payment[] = [];
    const [country, setCountry] = React.useState(countries[0]);
    const [payment, setPayment] = React.useState(
        // query.payment ? paymentMethods.find((e) => e.id === query.payment) : paymentMethods[0]
        null,
    );
    const [shipping, setShipping]= React.useState(
        null
        // query.shipping ? payment!.shipping.find((e) => e.id === query.shipping) : payment!.shipping[0]
);
    const [name, setName] = React.useState(query.receiver_name || '');
    // const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState(query.receiver_cellphone || query.receiver_phone || '');
    const [address, setAddress] = React.useState(query.receiver_address || '');


    const [cvsType, setCvsType] = React.useState(query.logistics_subtype || '');
    const [cvsName, setCvsName] = React.useState(query.receiver_store_name || '');

    const [city, setCity] = React.useState(taiwanCities[0].name);
    const districts = taiwanCities.find((e) => e.name === city)?.districts ?? [];
    const [district, setDistrict] = React.useState(districts ? districts[0] : '');
    const [error, setError] = React.useState<string | null>(null);
    const { isMobile } = useIsMobile();

    const checkout = async () => {
        // const collection = payment?.name.includes('取貨');
        // const r = await client.post('/order/checkout', {
        //     items: cart?.items.map((e) => ({
        //         product: {
        //             id:
        //             parseInt(
        //                 e.product.id,
        //             ),
        //             name: e.product.name,
        //         },
        //         count: e.count,
        //     })),
        //     ecpay_logistics_info: {
        //         address,
        //         phone,
        //         name,
        //         store: query.receiver_store_id,
        //         storeName: getCVSFullName(),
        //         cvsType,
        //         payment: payment!.name,
        //         collection,
        //     }
        // });
        // if (collection) {
        //     navigate('/order_success');
        //     return;
        // }
        // const result = await client
        //     .post(
        //         '/order/ecpay',
        //         {
        //             order: r.data.id,
        //         },
        //         // axiosConfig
        //     );
        // if (document) {
        //     document.getElementById('checkout_html')!.innerHTML = result.data;
        //     // @ts-ignore
        //     document?.getElementById("_form_aiochk")?.submit();
        // }
    }


    function getCVSFullName() {
        let brand = "";
        switch (cvsType) {
            case 'FAMI':
                brand = '全家';
                break;
            case 'UNIMART':
                brand = '7-11';
                break;
            default :
                return '';
        }
        return `${brand} ${cvsName}`;
    }

    function renderCvsInfo() {
        const selectCvs = async () => {
            // const r = await client.post('/order/ecpay_cvs_select',
            //     {
            //         cvsType: cvsType,
            //         collection: payment!.name.includes('取貨'),
            //         payment: payment!.id,
            //         shipping: shipping!.id,
            //     }
            // );
            // const url = r.data.url;
            // window.location = url;
        }
        if (!cvsType || !cvsName) {
            return <div style={{ margin: '8px 0px'}}>
                <CompactButton
                    onClick={async () => {
                        selectCvs();
                    }}
                >
                    選擇門市
                </CompactButton>
            </div>
        }
        return <div style={{ margin: '8px 0px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            {getCVSFullName()}
            <CompactButton
                onClick={async () => {
                    selectCvs();
                }}
            >
                重選門市
            </CompactButton>
        </div>;
    }


    return (
      <div>
            <Seo title="結帳"/>
            <div id="checkout_html"/>
            <div
                style={{
                    ...(
                        isMobile ? { flexDirection: 'column', padding: 24} : {}
                    ),
                    display: "flex",
                    maxWidth: 800,
                    margin: '0px auto',
                    justifyContent: 'center',
                    alignItems: 'start',
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                }}>
                    <div
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        運送區域
                    </div>
                    <div
                        style={{
                            flexGrow: 3,
                            marginTop: 8,
                        }}
                    >
                        <Dropdown
                            value={country.code}
                            options={countries.map((e) => ({
                                value: e.code,
                                label: e.cn,
                            }))
                            }
                            onChange={(e) => {
                                const selected = countries.find((s) => s.code === e) ?? countries[0];
                                setCountry(selected);
                            }}
                        />
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 16,
                    }}>
                        <div
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            付款方式
                        </div>
                        <div
                            style={{
                                flexGrow: 3,
                                marginTop: 8,
                            }}
                        >
                            {/* <Dropdown
                                value={payment!.id}
                                options={paymentMethods.map((e) => ({
                                    value: e.id,
                                    label: e.name,
                                }))
                                }
                                onChange={(e) => {
                                    const selectedPayment = paymentMethods.find((s) => s.id === e);
                                    setPayment(selectedPayment!);
                                    // @ts-ignore
                                    const newShipping = selectedPayment.shipping[0];
                                    // @ts-ignore
                                    setShipping(newShipping);
                                    if (newShipping!.name.includes('全家')) {
                                        setCvsType('FAMI');
                                    }
                                    else if (newShipping!.name.includes('7-11')) {
                                        setCvsType('UNIMART');
                                    }
                                    setCvsName('');
                                }}
                            /> */}
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: 'space-between',
                        flexDirection: "column",
                        marginTop: 16,
                    }}>
                        <div
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            運送方式
                        </div>
                        <div
                            style={{
                                flexGrow: 3,
                                marginTop: 8,
                            }}
                        >
                            {/* <Dropdown
                                value={shipping!.id}
                                options={payment!.shipping.map((e) => ({
                                    value: e.id,
                                    label: e.name,
                                }))
                                }
                                onChange={(e) => {
                                    // @ts-ignore
                                    const newShipping = payment.shipping.find((s) => s.id === e);
                                    // @ts-ignore
                                    setShipping(newShipping);
                                    if (newShipping!.name.includes('全家')) {
                                        setCvsType('FAMI');
                                    }
                                    else if (newShipping!.name.includes('7-11')) {
                                        setCvsType('UNIMART');
                                    }
                                    setCvsName('');
                                }}
                            /> */}
                        </div>
                    </div>
                    {/* {
                        shipping!.name.includes('便利商店') && renderCvsInfo()
                    } */}
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 16,
                    }}>
                        <div
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            收件資訊
                        </div>
                        <Field label="姓名">
                            <TextField
                                value={name}
                                onChange={(e) => setName(e)}
                            />
                        </Field>
                        <Field label="電話">
                            <TextField
                                value={phone}
                                onChange={(e) => setPhone(e)}
                            />
                        </Field>
                        {/* {
                            !shipping!.name.includes('便利') &&
                            <div style={{
                                display: "flex",
                                whiteSpace: "nowrap",
                                margin: "8px 0",
                                alignItems: "center",
                            }}>
                                <div style={{ color: "#666"}}>
                                    城市
                                </div>
                                <div style={{ flexGrow: 1}} />
                                <Dropdown
                                    value={city}
                                    options={taiwanCities.map((e) => ({
                                        value: e.name,
                                        label: e.name,
                                    }))
                                    }
                                    onChange={(e) => {
                                        setCity(e);
                                    }}
                                />
                                <div style={{ width: 8}} />
                                <Dropdown
                                    value={district as string}
                                    options={districts.map((e) => ({
                                        value: e.name,
                                        label: e.name,
                                    }))
                                    }
                                    onChange={(e) => {
                                        setDistrict(e);
                                    }}
                                />
                            </div>
                        }
                        {
                            country.code === "TW" &&
                            !shipping!.name.includes('便利') &&
                            <Field label="地址">
                                <TextField
                                    value={address}
                                    onChange={(e) => setAddress(e)}
                                />
                            </Field>
                        } */}
                    </div>
                </div>
                <div style={ isMobile ? {
                    height: 24,
                } : {
                    width: 24}}>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    {
                        // Object.values(cart?.items ?? []).map((e) => <div key={e.product.id} style={{
                        //     // margin: '1rem',
                        //     display: 'flex',
                        //     flexDirection: 'row',
                        //     alignItems: 'center',
                        // }}>
                        //     <div>
                        //
                        //         <img
                        //             style={{
                        //                 width: 80,
                        //                 height: 80,
                        //             }}
                        //             src={e.product.coverUrl}/>
                        //     </div>
                        //     <div
                        //         style={{
                        //             width: 24,
                        //         }}
                        //     >
                        //
                        //     </div>
                        //     <div
                        //         style={{
                        //             display: 'flex',
                        //             flexDirection: 'column',
                        //         }}
                        //     >
                        //         <div style={{
                        //             fontWeight: 'bold'
                        //         }}>
                        //
                        //             {e.product.name}
                        //         </div>
                        //         <div
                        //             style={{
                        //                 display: 'flex',
                        //                 justifyContent: 'space-between'
                        //             }}
                        //         >
                        //             <div>
                        //                 {e.product.specName} x {e.count}
                        //             </div>
                        //             <div>
                        //
                        //                 {`\$${e.product.price}`}
                        //             </div>
                        //         </div>
                        //     </div>
                        //     <div
                        //         style={{
                        //             flexGrow: 1,
                        //         }}
                        //     >
                        //
                        //     </div>
                        // </div>)
                    }
                    <div style={{width: "100%", height: 20}}/>
                    <BlackLine/>
                    <div style={{width: "100%", height: 20}}/>
                    <div
                        style={{
                            display: "flex",
                        }}
                    >

                        <div style={{
                            flexGrow: 1,
                        }}>
                            小計
                        </div>
                        <div>
                            {/*${totalPrice}*/}
                        </div>
                    </div>
                    {
                        // shipping?.fee > 0 && <div
                        //     style={{
                        //         display: "flex",
                        //         paddingTop: 8,
                        //     }}
                        // >
                        //     <div style={{
                        //         flexGrow: 1,
                        //     }}>
                        //         運費
                        //     </div>
                        //     <div>
                        //         ${shipping!.fee}
                        //     </div>
                        // </div>
                    }
                    <div style={{width: "100%", height: 20}}/>
                    <BlackLine/>
                    <div style={{width: "100%", height: 20}}/>
                    <div
                        style={{
                            display: "flex",
                        }}
                    >

                        <div style={{
                            flexGrow: 1,
                        }}>
                            總金額
                        </div>
                        <div>
                            {/*${totalPrice + shipping!.fee}*/}
                        </div>
                    </div>
                    <div style={{width: "100%", height: 20}}/>
                    <div style={{display: 'flex'}}>
                        <CompactButton onClick={async ()=> {
                            if (!name || !phone) {
                                setError('請填寫收件人資訊');
                                return;
                            } else  if (!shipping!.name.includes('便利') && !address) {
                                setError('請填寫收件地址');
                                return;
                            } else  if (payment!.name.includes('取貨') && (!cvsType || !cvsName)) {
                                setError('請選擇取貨門市');
                                return;
                            }
                            await checkout();
                        }}>
                            完成購買
                        </CompactButton>
                    </div>
                    {
                        error &&
                        <div style={{ color: 'red', marginTop:4}}>
                            {error}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Checkout;
